import { render, staticRenderFns } from "./ClientesEdit.vue?vue&type=template&id=688e9476&"
import script from "./ClientesEdit.vue?vue&type=script&lang=js&"
export * from "./ClientesEdit.vue?vue&type=script&lang=js&"
import style0 from "./ClientesEdit.vue?vue&type=style&index=0&id=688e9476&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports